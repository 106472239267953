<template>
    <mf-box>
        <div slot="header">
            Portcall States
        </div>
        <div v-if="selectedPortCallId">
            <mf-select
                class="portcall-states__select"
                placeholder="Search state to update"
                :options="displayedStatesDefinitions"
                label="name"
                @select="handleStateSelect"
            >
                <div slot="caret">
                    <mf-icon class="multiselect__select" icon-name="icon-down" />
                </div>
            </mf-select>
            <create-state-modal
                :is-opened="isCreateModalOpened"
                @close="closeModal('create')"
                :editable-state="editableState"
                :is-edit-mode="!!editableState"
            />
            <template v-if="definedRecentStates.length">
                <portcall-state-item
                    v-for="state in definedRecentStates"
                    :state="state"
                    :key="state.stateId"
                    @selectState="handleStateSelect"
                    @editState="handlEditState"
                />
            </template>
        </div>
        <div v-else class="empty">
            No portcall chosen
        </div>
        <update-state-modal
            :is-open="isUpdateModelOpened"
            :state="selectedState"
            :key="`isUpdateModelOpened: ${isUpdateModelOpened}`"
            :port-call-id="selectedPortCallId"
            :vessel-imo="vesselIMO"
            @close="closeModal('update')"
        />
    </mf-box>
</template>

<script>
    import { mapGetters } from 'vuex';
    import PortcallStateItem from '@/components/port-call-components/PortcallStateItem';
    import UpdateStateModal from '@/modals/UpdateStateModal';
    import CreateStateModal from '@/modals/CreateStateModal';

    export default {
        name: 'PortCallStates',
        components: {
            UpdateStateModal,
            PortcallStateItem,
            CreateStateModal,
        },
        computed: {
            ...mapGetters(['recentStates', 'statesDefinitions', 'selectedPortCallId', 'vesselIMO']),
            displayedStatesDefinitions() {
                return [{ name: 'Add a new state', stateId: 'New_State' }, ...this.statesDefinitions];
            },
            definedRecentStates() {
                return this.statesDefinitions.length ? this.recentStates.map(this.defineState) : [];
            },
        },
        mounted() {
            this.$store.dispatch('getStatesDefinitions');
        },
        data() {
            return {
                isUpdateModelOpened: false,
                isCreateModalOpened: false,
                selectedState: {},
                editableState: null,
            };
        },
        methods: {
            handleStateSelect(state) {
                if (state.stateId === 'New_State') {
                    this.isCreateModalOpened = true;
                } else {
                    this.isUpdateModelOpened = true;
                    this.selectedState = state;
                    this.$store.commit('addRecentState', state.stateId);
                }
            },
            handlEditState(state) {
                this.editableState = state;
                this.isCreateModalOpened = true;
            },
            closeModal(type) {
                if (type === 'update') {
                    this.isUpdateModelOpened = false;
                } else {
                    this.editableState = null;
                    this.isCreateModalOpened = false;
                }
            },
            defineState(stateId) {
                return this.statesDefinitions.find(definition => definition.stateId === stateId);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .portcall-states__select {
        margin-bottom: $space-16;
    }

    .empty {
        margin-top: $space-50;
        text-align: center;
        font-size: $font-16;
        line-height: $font-20;
        color: $marine-400;
        min-height: 100px;
    }
</style>
