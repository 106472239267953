<template>
    <mf-box :empty="!selectedPortCallId">
        <div slot="header">
            Chat
        </div>
        <div>
            <div class="chat__block" ref="chat-block">
                <infinite-loading v-if="messages.length" :distance="30" direction="top" @infinite="infiniteHandler">
                    <div slot="no-results" />
                    <div slot="no-more" />
                </infinite-loading>
                <div class="chat__message" v-for="(message, index) in messages" :key="index">
                    <div class="message__title">
                        <div class="message__user-name">
                            {{ message.username }}
                        </div>
                        <div class="message__date">
                            {{ message.createdAt }}
                        </div>
                    </div>
                    <div class="message__body">
                        {{ message.body }}
                    </div>
                </div>
            </div>
            <div>
                <div class="chat__interact-place">
                    <mf-input v-model="messageText" placeholder="Message"/>
                    <mf-button
                        class="chat__button"
                        pattern="primary-outline"
                        :disabled="isLoading"
                        @click="createMessage"
                    >
                        Send
                    </mf-button>
                </div>
            </div>
        </div>
        <div slot="empty-text">
            No portcall chosen
        </div>
    </mf-box>
</template>

<script>
    import InfiniteLoading from 'vue-infinite-loading';
    import { mapGetters } from 'vuex';
    import { chatApi, subscribeToChatUpdates, leaveFromChannel } from '@/services/chat-manager/chatApi';
    import dateFilter from '@/filters/date.filter';

    //todo: remove this, after implement user in backend side

    export default {
        name: 'Chat',
        components: {
            InfiniteLoading,
        },
        props: {
            portcallId: {
                type: Number,
                require: true,
            },
        },
        data() {
            return {
                messages: [],
                page: 1,
                lastPage: 1,
                messageText: '',
                isLoading: false,
            };
        },
        watch: {
            selectedPortCallId: {
                handler(newVal, oldVal) {
                    if (oldVal && newVal !== oldVal) {
                        this.messages = [];
                        this.page = 1;
                        leaveFromChannel(oldVal);
                    }
                    if (newVal) {
                        const addMessageCallback = message => {
                            //todo: change logic of getting user info (backend)
                            this.messages.push(message);
                            this.$nextTick(() => {
                                const element = this.$refs['chat-block'];
                                element.scrollTop = element.scrollHeight + 130;
                            });
                        };
                        subscribeToChatUpdates(newVal, addMessageCallback);
                        this.getChatMessages(newVal);
                    }
                },
                immediate: true,
            },
        },
        computed: {
            ...mapGetters(['selectedPortCallId', 'userInfo']),
        },
        methods: {
            async infiniteHandler($state) {
                if (this.page !== 1) {
                    await this.sendRequest(this.selectedPortCallId, $state);
                } else {
                    $state && $state.complete();
                }
            },
            async getChatMessages(portcallId) {
                await this.sendRequest(portcallId);
                this.$nextTick(() => {
                    const element = this.$refs['chat-block'];
                    element.scrollTop = element.scrollHeight;
                });
            },
            async sendRequest(portcallId, $state = null) {
                const params = {
                    page: this.page,
                };
                const response = await chatApi.index(portcallId, params);
                let messagesData = response.data.data.reverse();

                messagesData.forEach(message => {
                    //todo: change logic of getting user info (backend)
                    message.createdAt = dateFilter(Date.parse(message.createdAt), 'date time');
                });

                this.messages.unshift(...messagesData);
                this.lastPage = response.data.meta.last_page;

                if (this.page < this.lastPage) {
                    this.page += 1;
                    $state && $state.loaded();
                } else {
                    $state && $state.complete();
                }
            },
            async createMessage() {
                if (this.messageText !== '') {
                    try {
                        this.isLoading = true;

                        let message = {
                            portcall_id: this.selectedPortCallId,
                            body: this.messageText,
                            username: this.userInfo.fullName,
                        };
                        await chatApi.store(message);

                        this.messageText = '';
                        this.isLoading = false;
                    } catch {
                        this.$toasted.error('Error creating a new message');
                    }
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .chat__block {
        max-height: 400px;
        overflow-y: scroll;
        margin: $space-16 0;
        padding: 0 $space-4;
        @include scrollbar();
    }

    .chat__message {
        background-color: $marine-400;
        border-radius: $border-radius-8;
        padding: $space-8;
        margin-bottom: $space-20;
    }

    .message__title {
        display: flex;
        align-items: center;
    }

    .message__user-name {
        color: $marine-900;
        margin-right: $space-8;
    }

    .message__date {
        color: $marine-500;
        font-size: $font-13;
    }

    .message__body {
        padding: $space-8 0;
    }

    .chat__interact-place {
        display: flex;
        margin-bottom: $space-6;
    }

    .chat__button {
        margin-left: $space-16;
    }
</style>
