<template>
    <mf-box :empty="!portCallId || !statements">
        <div slot="header" class="statement__header">
            <div>
                Statement Log
            </div>
            <mf-dropdown without-icon :open-from-right="true">
                <div class="field__item" slot="link">
                    <mf-icon class="field__icon" icon-name="icon-filter" />
                    <div class="field__text">Filter</div>
                </div>
                <mf-divider />
                <div class="field__filter">
                    <div class="field__filter-search">
                        <mf-input v-model="searchString" is-search placeholder="Search"></mf-input>
                    </div>
                    <div class="field__filter-title">
                        Time Types:
                    </div>
                    <div class="field__filter-item field__filter-all-items-label">
                        <mf-checkbox @change="toggleAll" :checked="checkedSelectedAll">
                            All filters
                        </mf-checkbox>
                    </div>
                    <template v-for="(filterByTimeTypeOption, index) in filterByTimeTypeTimeTypeOptions">
                        <mf-divider
                            :key="`divider-${filterByTimeTypeOption}`"
                            v-if="index !== filterByTimeTypeTimeTypeOptions.length"
                        />
                        <div class="field__filter-item" :key="filterByTimeTypeOption">
                            <mf-checkbox
                                @change="changeFilter('timeType', filterByTimeTypeOption)"
                                :checked="checkSelectedFilterOption('timeType', filterByTimeTypeOption)"
                            >
                                {{ filterByTimeTypeOption }}
                            </mf-checkbox>
                        </div>
                    </template>
                    <mf-divider class="field__filter-divider"></mf-divider>
                    <div class="field__filter-title">
                        Owned:
                    </div>
                    <template v-for="(filterByInnovatorOption, index) in filterByInnovatorOptions">
                        <mf-divider
                            :key="`divider-${filterByInnovatorOption}`"
                            v-if="index !== filterByInnovatorOptions.length"
                        />
                        <div class="field__filter-item" :key="filterByInnovatorOption">
                            <mf-checkbox
                                @change="changeFilter('innovator', filterByInnovatorOption)"
                                :checked="checkSelectedFilterOption('innovator', filterByInnovatorOption)"
                            >
                                {{ filterByInnovatorOption }}
                            </mf-checkbox>
                        </div>
                    </template>
                </div>
            </mf-dropdown>
        </div>
        <div>
            <transition-group name="fade">
                <template v-for="(statement, index) in displayedStatement">
                    <statement-item :statement="statement" :key="index" />
                    <mf-divider :key="`divider-${index}`" />
                </template>
            </transition-group>
            <mf-button v-if="isButtonNeeded" class="statements__button" color="blue" @click="toggleAllStatements">
                {{ buttonText }}
            </mf-button>
        </div>
        <div slot="empty-text">
            {{ emptyText }}
        </div>
    </mf-box>
</template>

<script>
    import { mapGetters } from 'vuex';
    import StatementItem from '@/components/statement-components/StatementItem';
    const DEFAULT_AMOUNT_OF_DISPLAYED_STATEMENTS = 4;
    import { isEqual } from 'lodash';

    const TYPE_AND_FILTER_RELATIONSHIP = {
        timeType: 'filterByTimeType',
        innovator: 'filterByInnovator',
    };

    export default {
        name: 'StatementLog',
        components: { StatementItem },
        watch: {
            portCallId: {
                handler(val) {
                    val && this.$store.dispatch('getPortCallStatements', val);
                },
                immediate: true,
            },
        },
        computed: {
            ...mapGetters({ portCallId: 'selectedPortCallId', statements: 'selectedPortCallStatements' }),
            emptyText() {
                return !this.portCallId ? 'No portcall chosen' : 'No statements';
            },
            displayedStatement() {
                if (this.searchString || this.filterByTimeType.length > 0 || this.filterByInnovator.length > 0) {
                    let newStatementsArray = [];
                    newStatementsArray = this.statements.filter(value => {
                        return (
                            (!this.filterByTimeType.length || this.filterByTimeType.includes(value.timeType)) &&
                            (!this.filterByInnovator.length ||
                                this.filterByInnovator.includes(value.createdByUserOf)) &&
                            value.stateDefinition.toLowerCase().match(this.searchString.toLowerCase())
                        );
                    });
                    return newStatementsArray;
                } else {
                    return (
                        this.statements &&
                        (this.showAllStatements
                            ? this.statements
                            : this.statements.slice(0, DEFAULT_AMOUNT_OF_DISPLAYED_STATEMENTS))
                    );
                }
            },
            buttonText() {
                return this.showAllStatements ? 'Hide all' : 'Show all';
            },
            isButtonNeeded() {
                return this.showAllStatements
                    ? true
                    : this.statements &&
                          this.displayedStatement.length < this.statements.length &&
                          !this.searchString &&
                          !this.filterByTimeType.length;
            },
            checkedSelectedAll() {
                return isEqual(this.filterByTimeType, this.filterByTimeTypeTimeTypeOptions);
            },
        },
        data() {
            return {
                showAllStatements: false,
                filterByTimeTypeTimeTypeOptions: ['Actual', 'Estimated', 'Recommended', 'Target', 'Administrative'],
                filterByTimeType: [],
                filterByInnovatorOptions: ['Carmenta', 'Columbia'],
                filterByInnovator: [],
                searchString: '',
            };
        },
        methods: {
            toggleAllStatements() {
                this.showAllStatements = !this.showAllStatements;
            },
            toggleAll() {
                this.filterByTimeType =
                    this.filterByTimeType.length === this.filterByTimeTypeTimeTypeOptions.length
                        ? []
                        : this.filterByTimeTypeTimeTypeOptions;
            },
            checkSelectedFilterOption(type, option) {
                return this[TYPE_AND_FILTER_RELATIONSHIP[type]].includes(option);
            },
            changeFilter(type, option) {
                if (this[TYPE_AND_FILTER_RELATIONSHIP[type]].includes(option)) {
                    this[TYPE_AND_FILTER_RELATIONSHIP[type]] = this[TYPE_AND_FILTER_RELATIONSHIP[type]].filter(
                        existOption => existOption !== option
                    );
                } else {
                    this[TYPE_AND_FILTER_RELATIONSHIP[type]].push(option);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .statements__button {
        margin-top: $space-16;
    }

    .statement__header {
        display: flex;
        justify-content: space-between;
    }

    .field__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $white;
        font-size: $font-12;
        line-height: $font-14;
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
    }

    .field__icon {
        margin-bottom: $space-8;
    }

    .field__filter {
        border: 1px solid $marine-500;
        box-sizing: border-box;
        border-radius: 6px;
    }

    .field__filter-search {
        padding: $space-8;
        display: flex;
    }

    .field__filter-title {
        margin: $space-10 $space-16;
    }

    .field__filter-item {
        padding: $space-14 $space-16;
        font-size: $font-16;
        font-weight: normal;
    }

    .field__filter-all-items-label {
        background-color: $marine-600;
    }

    .field__filter-divider {
        height: 3px;
    }

    @import '@/assets/sass/transitions/fade';
</style>
