<template>
    <mf-box :empty="!(displayedStates && displayedStates[0] && displayedStates[0].length)">
        <div slot="header">
            Portcall Demurrage Timeline
        </div>
        <mf-responsive-table>
            <div class="demurrage-timeline">
                <div class="demurrage-timeline__row demurrage-timeline__top">
                    <template v-for="(state, index) in displayedStates[0]">
                        <div class="demurrage-timeline__item" :key="index">
                            <div v-if="index % 2 === 0" class="item_long">
                                <statement-preview :state="state"></statement-preview>
                                <div class="item__long-line"></div>
                            </div>
                            <div v-else class="item_short">
                                <statement-preview :state="state"></statement-preview>
                                <div class="item__short-line"></div>
                            </div>
                        </div>
                    </template>
                </div>
                <img class="demurrage-timeline__line" src="@/assets/images/line-big.png" alt="tine" />
                <div class="demurrage-timeline__row demurrage-timeline__bottom">
                    <template v-for="(state, index) in displayedStates[1]">
                        <div class="demurrage-timeline__item" :key="index">
                            <div v-if="index % 2 === 1" class="item_long">
                                <div class="item__long-line"></div>
                                <statement-preview :state="state"></statement-preview>
                            </div>
                            <div v-else class="item_short">
                                <div class="item__short-line"></div>
                                <statement-preview :state="state"></statement-preview>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </mf-responsive-table>
        <div slot="empty-text">
            {{ emptyText }}
        </div>
    </mf-box>
</template>
<script>
    import { mapGetters } from 'vuex';
    import StatementPreview from '@/components/statement-components/StatementPreview';

    export default {
        name: 'PortcallDemurrageTimeline',
        components: {
            StatementPreview,
        },
        computed: {
            ...mapGetters({
                portCallId: 'selectedPortCallId',
                statements: 'selectedPortCallStatements',
                statesDefinitions: 'statesDefinitions',
            }),
            displayedStates() {
                let states = this.statements && this.statements.filter(state => !state.isWithdrawn);

                let objectOfStatesParts = {
                    0: [],
                    1: [],
                };

                if (states && states.length) {
                    states.sort((firstState, secondState) => {
                        return new Date(firstState.time) - new Date(secondState.time);
                    });

                    states.length &&
                        states.forEach((state, index) => {
                            const currentState = this.statesDefinitions.find(item => {
                                return item.stateId === state.stateDefinition.replace(/\s+/g, '_');
                            });
                            state.name = currentState ? currentState['name'] : state.stateDefinition;

                            if (index % 2 === 0) {
                                objectOfStatesParts[0].push(state);
                            } else {
                                objectOfStatesParts[1].push(state);
                            }
                        });
                }

                return objectOfStatesParts;
            },
            emptyText() {
                return this.portCallId ? 'No Portcall Demurrage Timeline info' : 'No portcall chosen';
            },
        },
    };
</script>
<style lang="scss" scoped>
    .demurrage-timeline {
        min-width: 1350px;
        margin-bottom: 25px;
        width: fit-content;
    }

    .demurrage-timeline__top {
        margin-right: 75px;
    }

    .demurrage-timeline__row {
        display: flex;
        width: fit-content;
    }

    .demurrage-timeline__line {
        margin-top: -10px;
        margin-bottom: -14px;
        width: 100%;
        height: 45px;
    }

    .demurrage-timeline__item {
        display: flex;
        align-items: flex-end;
        margin-right: 8px;
    }

    .demurrage-timeline__bottom {
        margin: 0 25px 0 50px;
        .demurrage-timeline__item {
            justify-content: flex-end;
            align-items: flex-start;
        }
    }

    .item_short,
    .item_long {
        display: flex;
        flex-direction: column;
    }

    .item__long-line,
    .item__short-line {
        margin: 0 auto;
        border: 1px solid $marine-500;
    }

    .item__long-line {
        height: 200px;
    }

    .item__short-line {
        height: 75px;
    }
</style>
