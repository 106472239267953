<template>
    <div class="portcall-state" @click="selectState">
        {{ state.name }}
        <div class="button__wrapper">
            <mf-button v-if="state.isCustom" size="x-small" @click.stop="editState">Edit</mf-button>
            <mf-button size="x-small" @click.stop="removeState">Remove</mf-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PortcallStateItem',
        props: {
            state: {
                type: Object,
                required: true,
            },
        },
        methods: {
            selectState() {
                this.$emit('selectState', this.state);
            },
            editState() {
                this.$emit('editState', this.state);
            },
            removeState() {
                this.$store.commit('removeRecentState', this.state.stateId);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .portcall-state {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: $space-8 $space-16;
        &:not(:last-child) {
            margin-bottom: $space-8;
        }
        font-weight: 600;
        font-size: $font-16;
        line-height: $font-24;
        color: $white;
        border: 1px solid $marine-500;
        box-sizing: border-box;
        border-radius: 6px;

        .button__wrapper {
            display: flex;

            button:last-child {
                margin-left: 10px;
            }
        }
    }
</style>
